<template>
  <div class="recycle-container">
    <div class="recycle-style">
      <el-carousel height="830px">
        <el-carousel-item v-for="item in imgArray"
                          :key="item">
          <img :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
/**
 * create by zhangdepeng
 * 轮播图片组件
 */
import { toRefs, reactive } from 'vue'
export default {
  setup () {
    const state = reactive({
      imgArray: [
        'img/home-banner.png'
        // 'img/about-banner.png',
        // 'img/download-banner.png'
      ]
    })

    return {
      ...toRefs(state)
    }
  }

}
</script>
<style lang="less" scoped>
.recycle-container {
  position: relative;
  overflow: hidden;
  .recycle-style{
    // width:calc(100vw - 0.20rem);
  }
}
</style>
