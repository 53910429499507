<template>
  <div class="product-container">
    <img class="header-marker" src='img/product-feature.png' />
    <div class="product-detail">
      <div class="item">
        <img src="img/flight-assistant.png" />
        <h3>飞行助手</h3>
        <p>
          辅助导航及飞行状态信息实时显示，UTC时间、飞行高度、航向、航速、目的地、当前位置、预计到达时间、剩余距离、偏航距离和方位、卫星信号强度等信息。
        </p>
      </div>
      <div class="item">
        <img src="img/electronic-manual.png" />
        <h3>电子手册</h3>
        <p>
          支持电子书形式便捷阅读飞行手册等飞行资料。提供高亮标注、目录索引和搜索等功能。
        </p>
      </div>
      <div class="item">
        <img src="img/electronic-map.png" />
        <h3>电子航图</h3>
        <p>
          支持《国内航行情报资料汇编》NAIP在地图上显示和阅读功能，多种飞行要素标绘功能，特点、航线、圆形、椭圆形和多边形标绘及飞行要素保存和使用。
        </p>
      </div>
      <div class="item">
        <img src="img/compute.png" />
        <h3>性能计算</h3>
        <p>
          十多种飞行计算，包括偏流和地速计算、日出日落计算、速度距离时间换算、转弯半径、空速校正、油量计算、速度计算、高度换算和单位换算。
        </p>
      </div>
      <div class="item">
        <img src="img/flight-plan.png" />
        <h3>飞行计划</h3>
        <p>记录飞行任务过程中的飞行信息，同时提供飞行计划的填写与申请。</p>
      </div>
      <div class="item">
        <img src="img/electronic-measurment.png" />
        <h3>电子检查单</h3>
        <p>提供检查单、特情处理、计时器、电子图板、拍摄照片、视频录像和多媒体播放等实用工具。</p>
      </div>
      <div class="item">
        <img src="img/flight-warn.png" />
        <h3>飞行告警</h3>
        <p>提供偏航告警、进入禁飞区告警和无卫星信号告警等告警提示功能。</p>
      </div>
      <div class="item">
        <img src="img/data-link.png" />
        <h3>地空数据互联</h3>
        <p>
          与国内通航飞行服务站实现互联互通，通过整合面向飞行应用的数据，建立与数据服务平台的通讯。
        </p>
      </div>
    </div>
    <img class="machine-exhibition" src="img/plan-car.png" />
  </div>
</template>
<script>
/**
 * cerate by zhangdepeng
 * efb产品特点介绍及样式组件
 */
export default {

}
</script>
<style lang="less" scoped>
.product-container {
  text-align: center;
  .header-marker{
    margin-top:0.40rem;
  }
  .machine-exhibition {
    width: 100%;
  }
  .product-detail {
    margin-top: 0.30rem;
    padding: 0.05rem 0.11rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item {
      width: 1.6rem;
      padding: 20px 40px;
      h3 {
        font-family: PingFang-SC-Bold;
        font-size: 0.18rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2c4466;
      }
      p {
        font-family: PingFang-SC-Medium;
        font-size: 0.12rem;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 0.20rem;
        color: #1b1b1a;
        text-align: left;
      }
    }
  }
}
</style>
