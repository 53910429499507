<template>
  <div class="wrapper">
    <div class="header">
      <HeaderMenu />
    </div>
    <div class="section"><router-view /></div>
    <div class="footer">
      <FooterView />
    </div>
    <el-backtop :right="100" :bottom="100" />
  </div>
</template>

<script>
import FooterView from '@/components/FooterView'
import HeaderMenu from '@/components/HeaderMenu'
import { toRefs, reactive, onMounted } from 'vue'
export default {
  components: {
    FooterView,
    HeaderMenu
  },
  setup () {
    const state = reactive({
      activeIndex: '1'
    })

    onMounted(() => {
    })
    return {
      ...toRefs(state)
    }
  }

}
</script>

<style lang="less">
html,
body {
  min-height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header {
  flex: 0;
  background: #ffffff;
}
.section {
  flex: 1;
}
.footer {
  flex: 0;
  background: #ffffff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // overflow: hidden;
  min-height: 100vh;
}

.footer-style {
  min-height: 1.5rem;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
