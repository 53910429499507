<template>
  <div class="mark-container">
    <img src="img/application.png" />
    <div class="deacriptions">
      北京东进航空科技股份有限公司电子飞行服务包产品将便携机载电子服务体统与数据通信技术的结合融为一体，在辅助决策、提高航空服务保障能力和强化运行控制等方面发挥作用，可服务于多个场景。
    </div>
    <div class="img-container">
      <div class="item">
        <img src="img/aviation-industry.png" />
        <p>通航企业</p>
      </div>
      <div class="item">
        <img src="img/police.png" />
        <p>政府公安</p>
      </div>
      <div class="item">
        <img src="img/flight-manufacturer.png" />
        <p>直升机制造商</p>
      </div>
      <div class="item">
        <img src="img/training.png" />
        <p>航空体育</p>
      </div>
      <div class="item">
        <img src="img/arm.png" />
        <p>军方部队</p>
      </div>
      <div class="item">
        <img src="img/arme-police.png" />
        <p>武警部队</p>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * create by zhangdepeng
 * 市场应用内容展示及样式组件
 */
export default {

}
</script>
<style lang="less" scoped>
.mark-container {
  position: relative;
  text-align: center;
  img {
    margin-top: 60px;
  }
  .deacriptions {
    font-family: PingFang-SC-Medium;
    font-size: 0.12rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #000000;
    text-align: left;
    line-height: 0.25rem;
    margin: 0.05rem 0.25rem;
  }
  .img-container {
    text-align: center;
    .item {
      display: inline-block;
      img {
        margin: 0.05rem 0.1rem;
        width:3.0rem;
      }
      p {
        font-family: PingFang-SC-Medium;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2c4466;
      }
    }
  }
}
</style>
