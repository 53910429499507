export default {
  install (app) {
    app.directive('color', {
      created (el, binding) {
        // console.log('created', binding)
        el.style.backgroundColor = binding.value.color
      },
      updated (el, binding) {
        // console.log('updated', binding)
        el.style.backgroundColor = binding.value
      },
      beforeMount (el, binding) {
        // console.log('beforeMounted', el)
      },
      mounted (el, binding) {
        // console.log('mounted', el)
      },
      beforeUpdate (el, binding) {
        // console.log('beforeUpdate', el)
      }
    })
  }
}
