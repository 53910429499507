import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/DownLoadView.vue')
  },
  {
    path: '/payService',
    name: 'payService',
    component: () => import('../views/PayEFBView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/PayOrderView.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/PayView.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/PaySuccessView.vue')
  },
  {
    path: '/payMobileService',
    name: 'payMobileService',
    component: () => import('../views/PayEFBMobileView.vue')
  },
  {
    path: '/appLoad',
    name: 'appLoad',
    component: () => import('../views/AppLoadView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
