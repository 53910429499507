<template>
  <div class="menu-container">
    <img src="@/img/logo.png" />
    <div class="menu-style">
      <el-menu :default-active="activeIndex"
               class="el-menu-demo"
               mode="horizontal"
               @select="handleSelect">
        <el-menu-item index="1">首页</el-menu-item>
        <!-- <el-menu-item index="2">产品</el-menu-item>
        <el-menu-item index="3">应用</el-menu-item> -->
        <el-menu-item index="4">下载</el-menu-item>
        <el-menu-item index="5">购买</el-menu-item>
        <el-menu-item index="6">关于</el-menu-item>
      </el-menu>
    </div>
  </div>
  <!-- <router-view /> -->
</template>
<script>
/**
 * create by zhangdepeng
 * 首页头部菜单组件包括菜单样式和官网logo
 *
 */
import { useRouter } from 'vue-router'
import { toRefs, reactive, watch, watchEffect, onMounted } from 'vue'
export default {
  setup () {
    const router = useRouter()
    const state = reactive({
      name: 'svnskv',
      activeIndex: '1'
    })
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
      state.activeIndex = key
      if (key === '6') {
        router.push('/about')
      } else if (key === '1') {
        router.push('/')
      } else if (key === '4') {
        router.push('/download')
      } else if (key === '5') {
        router.push('/payService')
      }
    }
    watch([() => state.activeIndex], (newValue, oldValue) => {
      // console.log('old,new', oldValue, newValue)
    }, { immediate: true })

    watchEffect(() => {
      console.log(state.activeIndex)
    })
    onMounted(() => {
      console.log('ipoiu')
    })

    router.beforeEach((to, from) => {
      console.log('to', to, from)
      if (to.fullPath === '/') {
        state.activeIndex = '1'
      }
      if (to.fullPath === '/about') {
        state.activeIndex = '6'
      }
      if (to.fullPath === '/download') {
        state.activeIndex = '4'
      }
      if (to.fullPath === '/payService') {
        state.activeIndex = '5'
      }
    })

    return {
      ...toRefs(state),
      handleSelect
    }
  }
}
</script>
<style lang="less" scoped>
.menu-container {
  text-align: center;
  .menu-style {
    display: inline-block;
    width: 3.5rem;
    padding: 0.06rem;
    .el-menu--horizontal {
      justify-content: center;
      border: none !important;
    }
    .el-menu--horizontal > .el-menu-item {
      font-size: 0.12rem;
    }
  }
  img {
    width: 1.0rem;
    height: 0.2rem;
    vertical-align: middle;
  }
}
</style>
