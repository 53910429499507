<template>
  <div class="footer-container">
    <div class="link-menu">
      <div class="item item-distance">
        <dl>
          <dt>友情链接</dt>
          <dd>华为应用商店</dd>
          <dd>小米应用商店</dd>
          <dd>OPPO应用商店</dd>
          <dd>苹果应用商店</dd>
        </dl>
      </div>
      <div class="item item-distance">
        <dl>
          <dt>价格</dt>
          <dd>申请发票</dd>
        </dl>
      </div>
      <div class="item item-distance">
        <dl>
          <dt>服务</dt>
          <dd>使用帮助</dd>
          <dd>第三方接口</dd>
          <dd>找回密码</dd>
        </dl>
      </div>
      <div class="item item-distance">
        <dl>
          <dt>关于</dt>
          <dd>关于我们</dd>
          <dd>隐私声明</dd>
          <dd>用户协议</dd>
        </dl>
      </div>
      <div class="qr-style">
        <img src="@/img/qr-code.png" />
        <p>微信关注</p>
      </div>
    </div>
    <p>
      版权所有 @ <a href="http://www.mdr.com.cn/">北京东进航空科技股份有限公司</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备13025552号-5</a> 联系电话：010-51651919 E-mail：djefb@mdr.com.cn
    </p>
  </div>
</template>
<script>
/**
 * create by zhangdepeng
 * 首页尾部组件
 */
export default {
  name: 'FooterView'
}
</script>
<style lang="less" scoped>
.footer-container {
  background-image: linear-gradient(#212e39, #212e39),
    linear-gradient(198deg, #17208e 0%, #010378 100%);
  background-blend-mode: normal, normal;
  position: relative;
  .link-menu {
    position: relative;
    display: flex;
    .item-distance {
      margin: 0 0.5rem;
    }
    .item {
      align-items: flex-start;
      width: 1.0rem;
      height: 0.8rem;
      margin-top: 0.20rem;
      dl {
        text-align: center;
        dt {
          font-family: PingFang-SC-Bold;
          font-size: 0.09rem;
          font-weight: normal;
          font-stretch: normal;
          color: #ffffff;
          margin: 0;
        }
        dd {
          font-family: PingFang-SC-Regular;
          font-size: 0.07rem;
          font-weight: normal;
          font-stretch: normal;
          color: #ffffff;
          margin: 0.15rem 0 0 0;
        }
      }
    }
    .qr-style {
      width: 2.0rem;
      border-left: 1px solid #3c4751;
      height: 1.0rem;
      padding: 0.10rem 0.2rem 0.2rem 0.20rem;
      margin-top:0.15rem;
      p {
        font-family: PingFang-SC-Regular;
        font-size: 0.07rem;
        font-weight: normal;
        font-stretch: normal;
        color: #ffffff;
        margin: 0.05rem 0;
      }
    }
  }
  p {
    text-align: center;
    font-family: PingFang-SC-Regular;
    font-size: 0.07rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #8f9497;
    margin:0;
    padding: 0.08rem 0;
    a{
      color: #409eff;
    }
  }
}
</style>
