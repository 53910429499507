import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/utils/rem'
import directives from '@/utils/directive'
import imglazydirective from '@/utils/imglazyDirective'
createApp(App).use(store).use(router).use(directives).use(imglazydirective).use(ElementPlus, { size: 'small', zIndex: 3000 }).mount('#app')
