<template>
  <div class="home-container">
    <!-- <HeaderMenu nums='1' /> -->
    <div class="home-content">
      <EFBRecycle />
      <ProductFeature />
      <MarkApplication />
      <!-- <FooterView /> -->
    </div>
  </div>
</template>

<script>
/**
 * create by zhangdepeng
 * 官网首页
 */
import { reactive, toRefs } from 'vue'
import EFBRecycle from '@/components/EFBRecycle'
import ProductFeature from '@/components/ProductFeature'
import MarkApplication from '@/components/MarkApplicatin'
// import HeaderMenu from '@/components/HeaderMenu'
// import FooterView from '@/components/FooterView'

export default {
  components: {
    EFBRecycle,
    ProductFeature,
    MarkApplication
    // HeaderMenu
    // FooterView
  },
  setup () {
    const state = reactive({
      activeIndex: '1'
    })
    return {
      ...toRefs(state)
    }
  }

}
</script>
<style lang="less" scoped>
.home-container {
  position: relative;
  .home-content{
  }
}
</style>
